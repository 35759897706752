<template>
	<div class="signin-box">
		<el-card class="box-card" :body-style="{ padding: '0px' }" :class="{'no_border': no_border}">
		  <div style="font-weight: 700;text-align: center;margin:30px;">Welcome Back!</div>
		  <el-form class="signin-form" ref="signin-form" :model="form" :rules="rules">
		  	<el-form-item prop="email">
		  		<el-input placeholder="Email" v-model.trim="form.email" @input="form.email=form.email.toLowerCase()">
		  		</el-input>
		  	</el-form-item>
		  	<el-form-item prop="password">
		  		<el-input placeholder="Password" v-model="form.password" show-password @keyup.enter.native="onSubmit">
		  		</el-input>
		  	</el-form-item>
		  	<el-form-item style="text-align: center;">
		  		<el-button type="primary" size="mini" @click="onSubmit" :loading="loading" style="width: 100px;">
		  			{{loading ? 'Logging in' : 'Log in now'}}
		  		</el-button>
		  	</el-form-item>
		  </el-form>
		</el-card>
	</div>
</template>

<script>
	import {
		mapActions,
		mapMutations
	} from 'vuex'
	import common from '@/common/mixins/common.js'
	export default {
		mixins: [common],
		props: {
			no_border: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				form: {
					email: '',
					password: ''
				},
				loading: false,
				rules: {
					email: [{
						required: true,
						message: 'Blank input',
						trigger: ['blur', 'change']
					}, {
						type: 'email',
						message: 'This is not a valid email address.',
						trigger: ['blur', 'change']
					}],
					password: [{
						required: true,
						message: 'Password cannot be empty.',
						trigger: ['blur', 'change']
					}]
				}
			}
		},
		methods: {
			...mapActions(['login']),
			...mapMutations(['saveUser']),
			onSubmit() {
				if (this.loading) return
				this.$refs['signin-form'].validate(v => {
					if (v) {
						if (!v) return;
						this.loading = true
						this.login({...this.form})
						.then(data => {
							this.saveUser(data)
							this.$showSuccess('Successfully logged in.')
							this.loading = false
							this.$nextTick(() => {
								this.$router.replace('/')
							})
						})
						.catch(err => {
							this.$showErrMsg(err)
							this.loading = false
						})
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.signin-box {
		z-index: 100;
		width: calc(100vw - 40px);
		max-width: 350px;
		.el-card.box-card {
			padding: 0 30px;
		}
	}
	.no_border {
		border: unset;
		box-shadow: unset;
	}
</style>
