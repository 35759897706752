<template>
	<div id="login_page">
		<section id="login_page_header">
			<img src="@/assets/images/logo.png">
		</section>
		<section id="login_page_body">
			<signin />
		</section>
		<section id="login_page_footer">
			Copyright © 2024 Thinkninnovation. All Rights Reserved.
		</section>
	</div>
	
</template>

<script>
	import LoginComponents from '@/components/login'
	export default {
		components: {
			...LoginComponents
		},
	}
</script>

<style lang="scss" scoped>
	#login_page {
		height: 100%;
		background-color: #f1f2f4;
		display: flex;
		flex-direction: column;
		align-items: center;
		#login_page_header {
			padding:40px 0;
			img {
				max-width: 200px;
				height: 100%;
			}
		}
		#login_page_body {
			
		}
		#login_page_footer {
			padding-top: 100px;
			height: 40px;
			font-size: 12px;
			line-height: 40px;
			text-align: center;
			font-weight: 200;
		}
	}
</style>